import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getInterests = () => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/user/interest`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addInterest = (interest) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/tags/interest?tag=${interest}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getEvents = () => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(`${process.env._DB_HOST}api/v01/user/${userId}/events`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getMyDiscussions = ({ offset, limit }) => {
  try {
    const token = localStorage.getItem('token');
    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/profile/discussions?offset=${offset}&limit=${limit}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getMyEvents = ({ offset, limit }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/profile/events?offset=${offset}&limit=${limit}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getFollowers = ({ offset, limit, search }) => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/user/${userId}/followers?offset=${offset}&limit=${limit}&search=${search}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getGroups = ({ limit, offset, search = '' }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/profile/groups?offset=${offset}&limit=${limit}&search=${search}`,
      {
        method: 'GET',
        headers: {
          ...headers,
          tsec: JSON.parse(token),
        },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const editProfileData = (data) => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(`${process.env._DB_HOST}api/v01/user/${userId}`, {
      method: 'PATCH',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(data),
    }).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const addUsername = (username) => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(
      `${process.env._DB_HOST}api/v01/user/${userId}/username/${username}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify({ username }),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const finishTutorial = () => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(`${process.env._DB_HOST}api/v01/user/${userId}/tutorial`, {
      method: 'PUT',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const getSuggestion = ({ start, limit }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v10/suggestion?start=${start}&limit=${limit}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const reportUser = ({ userId, reportData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/user/${userId}/report`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(reportData),
    }).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const addGroup = (groupData) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/group`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(groupData),
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getUserDetails = (userId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOSTv12}api/v12/user/${userId}`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getUserByName = (username) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v11/username/${username}`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getUserPosts = (userId) => {
  const token = localStorage.getItem('token');
  return fetchData(`${process.env._DB_HOST}api/v10/user/${userId}/posts`, {
    method: 'GET',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
  }).then((response) => response.json());
};

export const getUserFollowers = ({ userId, offset, limit, search = '' }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/user/${userId}/followers?offset=${offset}&limit=${limit}&search=${search}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getUserFollowing = ({ userId, offset, limit, search = '' }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/user/${userId}/following?offset=${offset}&limit=${limit}&search=${search}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getMyRoutes = (userId) => {
  try {
    const token = localStorage.getItem('token');
    return fetchData(`${process.env._DB_HOSTv12}api/v12/user/${userId}`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
export const removeUserFromMyFollowers = ({ userId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/profile/followers/${userId}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};
