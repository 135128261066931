/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useRef, useState } from 'react';

import { useOutsideClick } from 'utils/hooks';
import { EditIconWithBg, ElipsisVertical } from 'public/assets/icons';
import { useWindowSize } from 'hooks';
import { IDropdown } from './IDropdown';

import {
  DropdownWrapper,
  DropdownButton,
  DropdownList,
  DropdownListItem,
  IconWrapper,
  TextWrapper,
  EditButton,
  DotButton,
  DropdownListContainer,
  SectionHeader,
} from './Dropdown.styles';

const Dropdown = ({
  listItems,
  sections,
  variant,
  disabled = false,
  viewProfile,
  donationMetricBanner,
  children,
  className,
  top,
  right,
  isOpen, // Optional prop to control visibility externally
  onToggle, // Optional callback for visibility changes
}: IDropdown) => {
  const [show, setShow] = useState<boolean>(false); // Default internal state
  const dropdownRef = useRef(null);

  const getWindowSize = useWindowSize();
  const getHeight = getWindowSize.height;

  const handleButtonClick = (e) => {
    e.stopPropagation();

    if (onToggle) {
      onToggle(!isOpen); // If onToggle is provided, trigger it
    } else {
      setShow((prevS) => !prevS); // Otherwise, use internal state
    }
  };

  useOutsideClick(dropdownRef, () => {
    if (onToggle) {
      onToggle(false); // Close dropdown if controlled externally
    } else {
      setShow(false); // Close dropdown if using internal state
    }
  });

  // Determine if the dropdown should be visible
  const isDropdownVisible = isOpen !== undefined ? isOpen : show;

  const getSectionItems = () => {
    return sections.map((section, index) => (
      <div key={index}>
        <SectionHeader>{section.header}</SectionHeader>
        {section.items.map((item, idx) => {
          if (item.customContent) {
            return (
              <div
                key={idx}
                onClick={(e) => {
                  e.stopPropagation();
                  if (onToggle) {
                    onToggle(false);
                  } else {
                    setShow(false);
                  }
                }}
              >
                {item.customContent}
              </div>
            );
          }
          return (
            !item?.hidden && (
              <DropdownListItem
                key={idx}
                onClick={() => {
                  item.onClick?.();
                  if (onToggle) {
                    onToggle(false);
                  } else {
                    setShow(false);
                  }
                }}
              >
                {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
                <TextWrapper>{item.text}</TextWrapper>
              </DropdownListItem>
            )
          );
        })}
      </div>
    ));
  };

  const getListItems = () => {
    return listItems?.map((item, idx) => {
      if (item?.customContent) {
        return (
          <div
            key={idx}
            onClick={(e) => {
              e.stopPropagation();
              if (onToggle) {
                onToggle(false);
              } else {
                setShow(false);
              }
            }}
          >
            {item.customContent}
          </div>
        );
      }
      return (
        <DropdownListItem
          key={idx}
          onClick={() => {
            item.onClick();
            if (onToggle) {
              onToggle(false);
            } else {
              setShow(false);
            }
          }}
        >
          {item?.icon && <IconWrapper>{item.icon}</IconWrapper>}
          <TextWrapper>{item?.text}</TextWrapper>
        </DropdownListItem>
      );
    });
  };

  const renderDropdownContent = () => {
    if (sections?.length) {
      return getSectionItems();
    }
    return getListItems();
  };

  const renderDropdownButtons = (type) => {
    switch (type) {
      case 'edit':
        return (
          <EditButton>
            <EditIconWithBg disabled={disabled} />
          </EditButton>
        );
      case 'dot':
        return (
          <DotButton>
            <ElipsisVertical />
          </DotButton>
        );

      default:
        break;
    }
  };

  return (
    <DropdownWrapper ref={dropdownRef} className={className}>
      <DropdownButton
        type="button"
        onClick={!disabled && handleButtonClick}
        disabled={disabled}
      >
        {children}
      </DropdownButton>
      <DropdownListContainer t={top} r={right}>
        {isDropdownVisible && (
          <DropdownList variant={variant} height={getHeight}>
            {viewProfile && (
              <div onClick={() => setShow(false)}>{viewProfile}</div>
            )}
            {renderDropdownContent()}
            {donationMetricBanner && (
              <div onClick={() => setShow(false)}>{donationMetricBanner}</div>
            )}
          </DropdownList>
        )}
      </DropdownListContainer>
    </DropdownWrapper>
  );
};

export default Dropdown;
